/**
 *
 * @description ContactForm.js
 * @author yikkok <yikok.yong@gmail.com>
 * @version 1.0.0
 * @since 18 July 2020
 *
 */

import * as React from 'react'
import {
  Grid,
  TextField,
  MenuItem,
  Select,
  Typography,
  Button,
  Box,
  InputLabel,
  CircularProgress,
  Dialog,
  DialogContent,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import ReCAPTCHA from 'react-google-recaptcha'
import Section from './Section'

const useStyles = makeStyles({
  fileInput: {
    borderWidth: 1,
    borderColor: 'rgb(195, 195, 195)',
    borderStyle: 'solid',
    color: 'rgb(195, 195, 195)',
    height: 40,
    marginTop: 17,
    borderRadius: 5,
    padding: 5,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: 13,
    lineHeight: '130%',
    '&:hover': {
      cursor: 'pointer',
    },
    '& > button': {
      fontSize: 13,
      lineHeight: '130%',
    },
  },
})

export default function ContactForm() {
  const classes = useStyles()

  const fileRef = React.useRef(null)
  const basicFormRef = React.useRef(null)

  const bodyWidthRef = React.useRef(null)
  const bodyThicknessRef = React.useRef(null)
  const overallWidthRef = React.useRef(null)
  const icLengthRef = React.useRef(null)
  const overallThicknessRef = React.useRef(null)
  const leadHeightRef = React.useRef(null)

  const leadframeThicknessRef = React.useRef(null)
  const mouldedThicknessRef = React.useRef(null)
  const leadframeLengthRef = React.useRef(null)
  const mouldedWidthRef = React.useRef(null)
  const leadframeWidthRef = React.useRef(null)
  const mouldedLengthRef = React.useRef(null)

  const internalDimensionRef = React.useRef(null)
  const outerDimensionRef = React.useRef(null)
  const waferframeThicknessRef = React.useRef(null)

  const [name, setName] = React.useState('')
  const [email, setEmail] = React.useState('')
  const [contact, setContact] = React.useState('')
  const [subject, setSubject] = React.useState('')
  const [productType, setProductType] = React.useState('1')
  const [message, setMessage] = React.useState('')
  const [file, setFile] = React.useState(null)
  const [loading, setLoading] = React.useState(false)

  const [ic, setIc] = React.useState({
    bodyThickness: 0,
    bodyWidth: 0,
    overallWidth: 0,
    overallThickness: 0,
    icLength: 0,
    leadHeight: 0,
  })
  const [leadframe, setLeadframe] = React.useState({
    leadframeThickness: 0,
    mouldedThickness: 0,
    leadframeLength: 0,
    mouldedWidth: 0,
    leadframeWidth: 0,
    mouldedLength: 0,
  })
  const [waferframe, setWaferframe] = React.useState({
    internalDimension: 0,
    outerDimension: 0,
    waferframeThickness: 0,
  })
  const [products, setProducts] = React.useState([])
  const [required, setRequired] = React.useState({
    name: '',
    email: '',
    contact: '',
    subject: '',
    message: '',
  })
  const [isAllowed, setIsAllowed] = React.useState(false)

  React.useEffect(() => {
    async function fetchProducts() {
      const res = await fetch(
        'https://api.almagsb.com/wp/wp-json/wc/v3/products?consumer_key=ck_ecb763e8eb8043969c90d8a46b47e195ea9ab06f&consumer_secret=cs_80f79b2c9806bf769bdea54d376229e4663afa64',
        {
          method: 'GET',
        }
      )
        .then(res => res.json())
        .catch(err => {
          console.log('err', err)
        })

      if (res) {
        setProducts(res)
        const selectedProduct = localStorage.getItem('@product')

        if (selectedProduct) {
          setProductType(Number(selectedProduct))
        }
      }
    }

    fetchProducts()
  }, [])

  function handleOnChangeIC(key) {
    return event => {
      const value = event.target.value

      if (value.replace(/[0-9.]/g, '').length > 0) {
        setIc(prev => ({
          ...prev,
          [key]: value.substring(0, value.length - 1),
        }))
      } else {
        setIc(prev => ({
          ...prev,
          [key]: value,
        }))
      }
    }
  }

  function handleOnChangeLeadframe(key) {
    return event => {
      const value = event.target.value

      if (value.replace(/[0-9.]/g, '').length > 0) {
        setLeadframe(prev => ({
          ...prev,
          [key]: value.substring(0, value.length - 1),
        }))
      } else {
        setLeadframe(prev => ({
          ...prev,
          [key]: value,
        }))
      }
    }
  }

  function handleOnChangeWaferframe(key) {
    return event => {
      const value = event.target.value

      if (value.replace(/[0-9.]/g, '').length > 0) {
        setWaferframe(prev => ({
          ...prev,
          [key]: value.substring(0, value.length - 1),
        }))
      } else {
        setWaferframe(prev => ({
          ...prev,
          [key]: value,
        }))
      }
    }
  }

  async function handleOnSubmit() {
    if (!(name && email && contact && subject && message)) {
      setRequired({
        name: (!name && 'Name is required') || '',
        email: (!email && 'Email is required') || '',
        contact: (!contact && 'Contact is required') || '',
        subject: (!subject && 'Subject is required') || '',
        message: (!message && 'Message is required') || '',
      })
      alert('Please fill in all required fields')

      if (basicFormRef.current) {
        window.scrollTo(0, basicFormRef.current.offsetTop - 120)
      }
      return
    }

    if (productType === '1') {
      alert('Please select a product.')
      return
    }

    const formData = new FormData()
    const selectedProduct = products.find(product => product.id === productType)

    formData.append('txtName', name)
    formData.append('txtEmail', email)
    formData.append('txtProductType', selectedProduct.name)
    formData.append('txtMobile', contact)
    formData.append('txtSubject', subject)
    formData.append('txtEnquiry', message)
    formData.append('txtBodyThickness', ic.bodyThickness)
    formData.append('txtBodyWidth', ic.bodyWidth)
    formData.append('txtOverallWidth', ic.overallWidth)
    formData.append('txtOverallThickness', ic.overallThickness)
    formData.append('txtICLength', ic.icLength)
    formData.append('txtLeadHeight', ic.leadHeight)

    formData.append('txtLeadframeThickness', leadframe.leadframeThickness)
    formData.append('txtLeadframeLength', leadframe.leadframeLength)
    formData.append('txtLeadframeWidth', leadframe.leadframeWidth)
    formData.append('txtMouldedThickness', leadframe.mouldedThickness)
    formData.append('txtMouldedLength', leadframe.mouldedLength)
    formData.append('txtMouldedWidth', leadframe.mouldedWidth)

    formData.append('txtInternalDimension', waferframe.internalDimension)
    formData.append('txtOuterDimension', waferframe.outerDimension)
    formData.append('txtWaferframeThickness', waferframe.waferframeThickness)

    formData.append('pro_file', file)

    if (isAllowed) {
      setLoading(true)
      const res = await fetch('https://almagsb.com/sendMail.php', {
        method: 'POST',
        body: formData,
      })
        .then(res => res.json())
        .catch(err => {
          setLoading(false)
          console.log(err)
        })

      if (res?.status === 'SUCCESS') {
        alert(res.message)
        setLeadframe({
          leadframeThickness: 0,
          mouldedThickness: 0,
          leadframeLength: 0,
          mouldedWidth: 0,
          leadframeWidth: 0,
          mouldedLength: 0,
        })
        setIc({
          bodyThickness: 0,
          bodyWidth: 0,
          overallWidth: 0,
          overallThickness: 0,
          icLength: 0,
          leadHeight: 0,
        })
        setWaferframe({
          internalDimension: 0,
          outerDimension: 0,
          waferframeThickness: 0,
        })
        setName('')
        setEmail('')
        setContact('')
        setSubject('')
        setMessage('')
        setProductType('1')
        setIsAllowed(false)
        localStorage.removeItem('@product')

        if (file) {
          setFile(null)
        }
      } else {
        alert(res?.message || 'Something went wrong.')
      }

      setLoading(false)
    }
  }

  return (
    <>
      <Grid container spacing={2} innerRef={basicFormRef}>
        <Grid item xs={12} sm={4}>
          <TextField
            fullWidth
            required
            label='Name (Required)'
            margin='dense'
            size='small'
            variant='outlined'
            value={name}
            onChange={event => {
              event.persist()
              setRequired(prev => ({
                ...prev,
                name: (!event.target.value && 'Name is required') || '',
              }))
              setName(event.target.value)
            }}
            error={!!required.name}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            fullWidth
            required
            label='Email (Required)'
            margin='dense'
            size='small'
            variant='outlined'
            value={email}
            onChange={event => {
              event.persist()
              setRequired(prev => ({
                ...prev,
                email: (!event.target.value && 'Email is required') || '',
              }))
              setEmail(event.target.value)
            }}
            error={!!required.email}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            fullWidth
            required
            label='Contact (Required)'
            margin='dense'
            size='small'
            variant='outlined'
            value={contact}
            inputProps={{ inputMode: 'decimal', pattern: '[0-9]*' }}
            onChange={event => {
              event.persist()
              setRequired(prev => ({
                ...prev,
                contact: (!event.target.value && 'Contact is required') || '',
              }))
              setContact(event.target.value)
            }}
            error={!!required.contact}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} alignItems='center'>
        <Grid item xs={12} sm={4}>
          <InputLabel shrink>Product Type</InputLabel>
          <Select
            required
            fullWidth
            variant='outlined'
            margin='dense'
            value={productType}
            onChange={event => setProductType(event.target.value)}
          >
            <MenuItem value='1'>Select a product type</MenuItem>
            {products.map(product => (
              <MenuItem value={product.id} key={product.id}>
                {product.name}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={12} sm={4}>
          <div
            type='button'
            className={classes.fileInput}
            onClick={() => {
              if (fileRef.current) {
                fileRef.current.click()
              }
            }}
            onKeyDown={e => {
              if (e.key === 'Enter' && fileRef.current) {
                fileRef.current.click()
              }
            }}
            tabIndex={0}
          >
            {file?.name || 'No file chosen'}
            <Button size='small'>Choose file</Button>
          </div>
          <TextField
            margin='dense'
            fullWidth
            type='file'
            onChange={event => {
              event.persist()
              setFile(event.target.files[0])
            }}
            style={{ display: 'none' }}
            InputProps={{ inputRef: fileRef }}
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label='Subject (Required) *'
            margin='dense'
            size='small'
            variant='outlined'
            value={subject}
            onChange={event => {
              event.persist()
              setRequired(prev => ({
                ...prev,
                subject: (!event.target.value && 'Subject is required') || '',
              }))
              setSubject(event.target.value)
            }}
            error={!!required.subject}
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <TextField
            variant='outlined'
            fullWidth
            margin='dense'
            multiline
            rowsMax={10}
            rows={5}
            label='Message (Required) *'
            placeholder='Please write your message here'
            value={message}
            onChange={event => {
              event.persist()
              setRequired(prev => ({
                ...prev,
                message: (!event.target.value && 'Message is required') || '',
              }))
              setMessage(event.target.value)
            }}
            style={{ lineHeight: '1.5rem' }}
            error={!!required.message}
          />
        </Grid>
      </Grid>

      {(productType === 71 || productType === 19) && (
        <>
          <Typography
            style={{ fontSize: '2rem', lineHeight: '2.5rem', fontWeight: 300, marginTop: 30, marginBottom: 20 }}
          >
            IC POD / Dimension
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <img src={require('../images/enquiry/ic-unit.png')} alt='ic dimension' />
            </Grid>
          </Grid>
          <Section>
            <Grid container spacing={1} justify='space-around'>
              <Grid item xs={12} sm={2}>
                <TextField
                  inputRef={bodyThicknessRef}
                  InputLabelProps={{ style: { fontSize: 12 } }}
                  variant='outlined'
                  fullWidth
                  margin='dense'
                  label='Body Thickness (mm)'
                  inputProps={{ min: 0, inputMode: 'decimal', pattern: '[0-9.]*' }}
                  value={ic.bodyThickness}
                  onChange={handleOnChangeIC('bodyThickness')}
                  onFocus={() => {
                    bodyThicknessRef.current.select()
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <TextField
                  inputRef={bodyWidthRef}
                  InputLabelProps={{ style: { fontSize: 12 } }}
                  variant='outlined'
                  fullWidth
                  margin='dense'
                  label='Body Width (mm)'
                  inputProps={{
                    min: 0,
                    inputMode: 'decimal',
                    pattern: '[0-9.]*',
                  }}
                  value={ic.bodyWidth}
                  onChange={handleOnChangeIC('bodyWidth')}
                  onFocus={event => {
                    event.target.select()
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <TextField
                  inputRef={leadHeightRef}
                  InputLabelProps={{ style: { fontSize: 12 } }}
                  variant='outlined'
                  fullWidth
                  margin='dense'
                  label='Lead Height (mm)'
                  inputProps={{
                    min: 0,
                    inputMode: 'decimal',
                    pattern: '[0-9.]*',
                  }}
                  value={ic.leadHeight}
                  onChange={handleOnChangeIC('leadHeight')}
                  onFocus={event => {
                    event.target.select()
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <TextField
                  inputRef={overallWidthRef}
                  InputLabelProps={{ style: { fontSize: 12 } }}
                  variant='outlined'
                  fullWidth
                  margin='dense'
                  label='Overall Width (mm)'
                  inputProps={{ min: 0, inputMode: 'decimal', pattern: '[0-9.]*' }}
                  value={ic.overallWidth}
                  onChange={handleOnChangeIC('overallWidth')}
                  onFocus={() => {
                    overallWidthRef.current.select()
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <TextField
                  inputRef={overallThicknessRef}
                  InputLabelProps={{ style: { fontSize: 12 } }}
                  variant='outlined'
                  fullWidth
                  margin='dense'
                  label='Overall Thickness (mm)'
                  inputProps={{ min: 0, inputMode: 'decimal', pattern: '[0-9.]*' }}
                  value={ic.overallThickness}
                  onChange={handleOnChangeIC('overallThickness')}
                  onFocus={() => {
                    overallThicknessRef.current.select()
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <TextField
                  inputRef={icLengthRef}
                  InputLabelProps={{ style: { fontSize: 12 } }}
                  variant='outlined'
                  fullWidth
                  margin='dense'
                  label='IC Length (mm)'
                  inputProps={{ min: 0, inputMode: 'decimal', pattern: '[0-9.]*' }}
                  value={ic.icLength}
                  onChange={handleOnChangeIC('icLength')}
                  onFocus={() => {
                    icLengthRef.current.select()
                  }}
                />
              </Grid>
            </Grid>
          </Section>
        </>
      )}

      {(productType === 76 || productType === 42 || productType === 38 || productType === 19) && (
        <>
          <Section>
            <Typography style={{ fontSize: '2rem', lineHeight: '2.5rem', fontWeight: 300, marginBottom: 30 }}>
              Leadframe POD / Dimension
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <img src={require('../images/enquiry/leadframe.png')} alt='leadframe dimension' />
              </Grid>
            </Grid>
          </Section>
          <Section>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={2}>
                <TextField
                  inputRef={leadframeWidthRef}
                  InputLabelProps={{ style: { fontSize: 12 } }}
                  variant='outlined'
                  fullWidth
                  margin='dense'
                  label='Leadframe width (mm)'
                  inputProps={{ min: 0, inputMode: 'decimal', pattern: '[0-9.]*' }}
                  value={leadframe.leadframeWidth}
                  onChange={handleOnChangeLeadframe('leadframeWidth')}
                  onFocus={() => {
                    leadframeWidthRef.current.select()
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <TextField
                  inputRef={leadframeLengthRef}
                  InputLabelProps={{ style: { fontSize: 12 } }}
                  variant='outlined'
                  fullWidth
                  margin='dense'
                  label='Leadframe length (mm)'
                  inputProps={{ min: 0, inputMode: 'decimal', pattern: '[0-9.]*' }}
                  value={leadframe.leadframeLength}
                  onChange={handleOnChangeLeadframe('leadframeLength')}
                  onFocus={() => {
                    leadframeLengthRef.current.select()
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <TextField
                  inputRef={mouldedWidthRef}
                  InputLabelProps={{ style: { fontSize: 12 } }}
                  variant='outlined'
                  fullWidth
                  margin='dense'
                  label='Moulded width (mm)'
                  inputProps={{ min: 0, inputMode: 'decimal', pattern: '[0-9.]*' }}
                  value={leadframe.mouldedWidth}
                  onChange={handleOnChangeLeadframe('mouldedWidth')}
                  onFocus={() => {
                    mouldedWidthRef.current.select()
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <TextField
                  inputRef={mouldedLengthRef}
                  InputLabelProps={{ style: { fontSize: 12 } }}
                  variant='outlined'
                  fullWidth
                  margin='dense'
                  label='Moulded length (mm)'
                  inputProps={{ min: 0, inputMode: 'decimal', pattern: '[0-9.]*' }}
                  value={leadframe.mouldedLength}
                  onChange={handleOnChangeLeadframe('mouldedLength')}
                  onFocus={() => {
                    mouldedLengthRef.current.select()
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <TextField
                  inputRef={leadframeThicknessRef}
                  InputLabelProps={{ style: { fontSize: 12 } }}
                  variant='outlined'
                  fullWidth
                  margin='dense'
                  label='Leadframe Thickness (mm)'
                  inputProps={{ min: 0, inputMode: 'decimal', pattern: '[0-9.]*' }}
                  value={leadframe.leadframeThickness}
                  onChange={handleOnChangeLeadframe('leadframeThickness')}
                  onFocus={() => {
                    leadframeThicknessRef.current.select()
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <TextField
                  inputRef={mouldedThicknessRef}
                  InputLabelProps={{ style: { fontSize: 12 } }}
                  variant='outlined'
                  fullWidth
                  margin='dense'
                  label='Moulded Thickness (mm)'
                  inputProps={{ min: 0, inputMode: 'decimal', pattern: '[0-9.]*' }}
                  value={leadframe.mouldedThickness}
                  onChange={handleOnChangeLeadframe('mouldedThickness')}
                  onFocus={() => {
                    mouldedThicknessRef.current.select()
                  }}
                />
              </Grid>
            </Grid>
          </Section>
        </>
      )}

      {(productType === 92 || productType === 56 || productType === 50 || productType === 19) && (
        <>
          <Section>
            <Typography style={{ fontSize: '2rem', lineHeight: '2.5rem', fontWeight: 300, marginBottom: 30 }}>
              Wafer frame POD / Dimension
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={3} />
              <Grid item xs={12} sm={6}>
                <img src={require('../images/enquiry/waferframe.png')} alt='waferframe dimension' />
              </Grid>
              <Grid item xs={12} sm={3} />
            </Grid>
          </Section>
          <Section>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={4}>
                <TextField
                  inputRef={internalDimensionRef}
                  InputLabelProps={{ style: { fontSize: 12 } }}
                  variant='outlined'
                  fullWidth
                  margin='dense'
                  label='Internal Dimension (mm)'
                  inputProps={{ min: 0, inputMode: 'decimal', pattern: '[0-9.]*' }}
                  value={waferframe.internalDimension}
                  onChange={handleOnChangeWaferframe('internalDimension')}
                  onFocus={() => {
                    internalDimensionRef.current.select()
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  inputRef={outerDimensionRef}
                  InputLabelProps={{ style: { fontSize: 12 } }}
                  variant='outlined'
                  fullWidth
                  margin='dense'
                  label='Outer Dimension (mm)'
                  inputProps={{ min: 0, inputMode: 'decimal', pattern: '[0-9.]*' }}
                  value={waferframe.outerDimension}
                  onChange={handleOnChangeWaferframe('outerDimension')}
                  onFocus={() => {
                    outerDimensionRef.current.select()
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  inputRef={waferframeThicknessRef}
                  InputLabelProps={{ style: { fontSize: 12 } }}
                  variant='outlined'
                  fullWidth
                  margin='dense'
                  label='Waferframe Thickness (mm)'
                  inputProps={{ min: 0, inputMode: 'decimal', pattern: '[0-9.]*' }}
                  value={waferframe.waferframeThickness}
                  onChange={handleOnChangeWaferframe('waferframeThickness')}
                  onFocus={() => {
                    waferframeThicknessRef.current.select()
                  }}
                />
              </Grid>
            </Grid>
          </Section>
        </>
      )}

      <Grid container>
        <Grid item xs={12}>
          <ReCAPTCHA
            sitekey='6LfOurQZAAAAACJ5GDEbjIfA9IE2wggzNEpoGSnn'
            onChange={async token => {
              if (!token) {
                setIsAllowed(false)
                return
              }

              const formData = new FormData()

              formData.append('recaptchaToken', token)

              const res = await fetch('https://almagsb.com/verifyRecaptcha.php', {
                method: 'POST',
                body: formData,
              })
                .then(res => res.json())
                .catch(err => {
                  console.log(err)
                })

              if (res?.status === 'SUCCESS') {
                setIsAllowed(res?.data?.success)
              } else {
                alert('Something went wrong.')
              }
            }}
          />
        </Grid>
      </Grid>

      <Box marginBottom={4} marginTop={4}>
        <Grid container justify='center'>
          <Grid item xs={12} sm={3}>
            <Button variant='contained' color='primary' fullWidth onClick={handleOnSubmit} disabled={!isAllowed}>
              Submit
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Dialog open={loading}>
        <DialogContent>
          <CircularProgress />
        </DialogContent>
      </Dialog>
    </>
  )
}
