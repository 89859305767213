/**
 *
 * @description enquiry.js
 * @author yikkok <yikok.yong@gmail.com>
 * @version 1.0.0
 * @since 18 July 2020
 *
 */

import * as React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Section from '../components/Section'
import ContactForm from '../components/ContactForm'

export default function enquiry() {
  return (
    <Layout>
      <SEO title='Enquiry | Almag Sdn. Bhd.' />
      <Section>
        <ContactForm />
      </Section>
    </Layout>
  )
}
